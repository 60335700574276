import getConfig from "next/config";
import moment from "moment";
import paths from "./paths";

const { publicRuntimeConfig } = getConfig();

export const getSailingData = async (odysseusId) => {
  try {
    const sailingDataResult = await fetch(
      `${publicRuntimeConfig.POSEIDON_NEXT_API}/sailings/?limit=1&odysseus_id=${odysseusId}`
    );
    if (sailingDataResult.status === 200) {
      const json = await sailingDataResult.json();
      const sailingData = json.results[0];
      return sailingData;
    }
  } catch (e) {
    // TODO handle error
  }
  return null;
};

// formats data to be sent to Shandalar SavedItems table when favorited
const formatData = async (odysseusIds, isToBeFavorited) => ({
  items: odysseusIds.map((odysseusId) => {
    const product_id = `gotosea-sailing-${odysseusId.toString()}`;
    return {
      item_id: odysseusId.toString(),
      // product_id is the cross-site identifier we use for Blueshift items
      product_id,
      metadata: {
        odysseus_id: odysseusId.toString(),
        product_id,
      },
      favorite: isToBeFavorited,
      alert: "ALL",
    };
  }),
});

const formatSailthru = (sailing) => {
  const {
    display_name,
    ship: {
      name: ship_name,
      image_url_medium: image_url,
      cruiseline: { name: cruiseline_name },
    },
    start_date,
    end_date,
    inside_price,
    outside_price,
    balcony_price,
    odysseus_id,
    urlname,
    itinerary,
  } = sailing;
  const { path } = paths.profile(urlname, odysseus_id);
  const departurePort = itinerary.stop_set[0].display_name;
  const arrivalPort =
    itinerary.stop_set[itinerary.stop_set.length - 1].display_name;
  const startDateFormatted = moment(start_date).format("MMM DD, YYYY");
  const endDateFormatted = moment(end_date).format("MMM DD, YYYY");

  return [
    { label: "fave_sailing_name", value: display_name },
    { label: "fave_ship_name", value: ship_name },
    { label: "fave_image_url", value: image_url },
    { label: "fave_cruiseline_name", value: cruiseline_name },
    { label: "fave_departure_port", value: departurePort },
    { label: "fave_arrival_port", value: arrivalPort },
    { label: "fave_start_date", value: startDateFormatted },
    { label: "fave_end_date", value: endDateFormatted },
    { label: "fave_inside_price", value: inside_price },
    { label: "fave_ocean_price", value: outside_price },
    { label: "fave_balcony_price", value: balcony_price },
    { label: "fave_url", value: path },
    // A flag for sailthru to trigger sending out the email on first save
    { label: "fave_email_flag", value: true },
  ];
};

export const getFormattedSailthruData = async (odysseusId) => {
  const sailing = await getSailingData(odysseusId);
  if (!sailing) return null;
  return formatSailthru(sailing);
};

export const setSailthruFaveVars = async (odysseusId) => {
  try {
    const sailthruData = await getFormattedSailthruData(odysseusId);
    if (!sailthruData) return;
    const formData = new FormData();
    sailthruData.forEach(({ label, value }) => {
      formData.append(label, value);
    });
    await fetch("/emailprefs/update-sailthru-data", {
      method: "POST",
      body: formData,
    });
  } catch (e) {
    // TODO handle error
  }
};

const updateSavedFavorites = async (data) => {
  try {
    const res = await fetch(`/emailprefs/user/saved-items?type=sailing`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    if (res.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

/**
 * Updates a single saved sailing to be favorited or not favorited
 *
 * @param {number} odysseusId - the odysseus id of the sailing
 * @param {boolean} isToBeFavorited - whether the sailing will be favorited or unfavorited
 * @return {Promise<boolean>} true/false whether the call succeeded
 */
export const setSingleFavorite = async (odysseusId, isToBeFavorited) => {
  const data = await formatData([odysseusId], isToBeFavorited);
  if (isToBeFavorited) {
    setSailthruFaveVars(odysseusId);
  }
  return updateSavedFavorites(data);
};

/**
 * Updates multile saved sailings to be favorited or not favorited
 *
 * @param {number[]} odysseusIds - array of odysseus ids
 * @param {boolean} isToBeFavorited - whether the sailing will be favorited or unfavorited for all sailings
 * @return {Promise<boolean>} true/false whether the call succeeded
 */
export const setMultipleFavorites = async (odysseusIds, isToBeFavorited) => {
  const data = await formatData(odysseusIds, isToBeFavorited);
  return updateSavedFavorites(data);
};

/**
 * Returns the current user's saved favorite sailing IDs.
 *
 * @return {Promise<{error: object|number}>, Promise<{results: number[]}>}
 *          Results as an array of odysseus ids and any errors
 */
export const getSavedFavorites = async () => {
  try {
    const res = await fetch(
      `/emailprefs/user/saved-items?type=sailing&favorite=true`
    );
    if (res.status === 200) {
      const { items } = await res.json();
      const favorites = items.map(({ item_id }) => parseInt(item_id, 10));
      return { results: favorites };
    }
    return { error: res.status };
  } catch (e) {
    return { error: e };
  }
};
