import React, { useState } from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { green60, white00 } from "styles/colors";
import media from "styles/media";
import fontSizes from "styles/fontSizes";
import spacing from "styles/spacing";
import paths from "utils/paths";
import CloseIcon from "public/icons/close.svg";

const Container = styled.div`
  // positioning styles
  // =========================
  position: fixed;
  z-index: 99999;
  left: 50%;
  border-radius: 5px;
  width: 344px;
  height: 52px;
  ${fontSizes[2]};
  ${media.medium`
      width: 500px;
      height: 54px;
      ${fontSizes[1]};
    `}
  padding: 0 ${spacing[4]};
  background-color: ${green60};
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  // transition styles (slide in from bottom)
  // =========================
  bottom: -100px;
  transition:
    bottom 150ms linear,
    opacity 150ms ease-in-out;
  @media (prefers-reduced-motion: reduce) {
    transition: opacity 150ms;
  }
  &.toast-enter-done {
    opacity: 1;
    bottom: 0.5rem;
  }
  &.toast-exit &.toast-enter {
    opacity: 0;
    bottom: -100px;
  }
`;

const CloseButton = styled.button`
  --webkit-appearance: none;
  border: none;
  outline: none;
  color: ${white00};
  background-color: transparent;
  position: relative;
  top: 1px;
  margin-left: 0;
  ${media.medium`
    margin-left: 12px;
  `}
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  :hover {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgb(255, 255, 255, 0.25);
  }
  :focus {
    cursor: pointer;
    outline: none;
    background-size: 2.5rem;
    background-color: rgb(255, 255, 255, 0.25);
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: ${white00};
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: relative;
  right: 5px;
`;

const ButtonLink = styled.button`
  color: ${white00};
  border: none;
  border-radius: 5px;
  font-weight: bold;
  position: relative;
  background-color: transparent;
  ${fontSizes[0]};
  ${media.medium`
      ${fontSizes[1]};
    `}
  text-decoration: none;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.25);
    transition: all 0.5s ease;
  }
  bottom: 7px;
`;

const Toast = ({
  isOpen,
  closeToast,
  infoText = "Sailing has been saved!",
  linkText = "See saved sailings",
  linkUrl = paths.favorites().canonicalUrl,
}) => {
  return (
    <CSSTransition in={isOpen} timeout={350} classNames="toast">
      <Container tabIndex="0">
        <ContentContainer>
          <div>{infoText}</div>
          <div>
            <ButtonLink
              onClick={(e) => {
                e.preventDefault();
                window.location.href = linkUrl;
              }}
            >
              {linkText}
            </ButtonLink>
            <CloseButton onClick={closeToast}>
              <StyledCloseIcon fill={white00} height="26px" width="26px" />
            </CloseButton>
          </div>
        </ContentContainer>
      </Container>
    </CSSTransition>
  );
};

export const useToast = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [timeoutId, setTimoutId] = useState();

  const closeToast = () => {
    setIsOpen(false);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimoutId(undefined);
    }
  };
  const openToast = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsOpen(true);
    const newId = setTimeout(() => setIsOpen(false), 3150);
    setTimoutId(newId);
  };
  return [isOpen, openToast, closeToast];
};

Toast.propTypes = {
  isOpen: PropTypes.bool,
  closeToast: PropTypes.func,
  infoText: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
};

export default Toast;
