// https://nextjs.org/docs/pages/building-your-application/routing/custom-app
// Next.js uses the App component to initialize pages.

/* eslint-disable @next/next/no-page-custom-font */
// Base
import React, { useState, useEffect } from "react";
import Script from "next/script";
import Router from "next/router";
import getConfig from "next/config";
// Utilities
import { firePageView, trackCognitoSignup } from "utils/tracking";
import { setAuthConfigValues } from "@usn/atlas-auth";
import { FavoritingProvider } from "utils/favoritingContext";
import { setCsrfToken, setCognitoVertical } from "utils/cookieManager";
// Global Styles
import "../styles/global.css";
import "@aerialist7/react-dates/lib/css/_datepicker.css";
// Components
// eslint-disable-next-line import/no-unresolved
import { PagesTopLoader } from "nextjs-toploader/pages";
import Head from "next/head";
import ErrorBoundary from "components/ErrorBoundary";

if (typeof window !== "undefined") {
  require("utils/privacy"); // eslint-disable-line global-require
}

const { publicRuntimeConfig } = getConfig();

// Production values
setAuthConfigValues({
  AWS_CLIENT_ID: "4stle233n3ru8uah0a8brr72e0",
  USER_POOL_ID: "us-east-1_u0gAtPW9f",
  OAUTH_DOMAIN: "auth.gotosea.com",
  AWS_REDIRECT_URI: "https://www.gotosea.com/login-redirect",
  GOOGLE_CLIENT_ID:
    "1074827478134-j7p3riijqg8ulrklm8ftvqg97juch1q5.apps.googleusercontent.com",
  LOGOUT_URL: "https://www.gotosea.com/emailprefs/logout-redirect",
  COOKIE_DOMAIN: "gotosea.com",
  POST_SIGNUP_CALLBACK: (user) => {
    const cleanEmail = user.attributes.email.trim().toLowerCase();
    window?.blueshift?.identify({
      email: cleanEmail,
      is_gotosea_user: true,
    });
    window?.blueshift?.track("subscribe_interest", {
      email: cleanEmail,
      topic: "gotosea special offers, promotions and surveys",
    });
    trackCognitoSignup();
  },
});

// Lower-environment values
if (typeof window !== "undefined") {
  // Set session cookie to mark that this is the `gotosea` frontend
  setCognitoVertical();

  // For local development. Our Google UAT client app settings allow for scripts
  // loaded from localhost and localhost:3009.
  if (
    window.location?.hostname.includes("-sand") ||
    window.location?.hostname === "localhost"
  ) {
    setAuthConfigValues({
      AWS_CLIENT_ID: "26begqed5jfgd8l37g6j0f85rd",
      USER_POOL_ID: "us-east-1_uhvOON0Lg",
      OAUTH_DOMAIN: "auth-uat.gotosea.com",
      AWS_REDIRECT_URI: "https://www-uat26.gotosea.com/login-redirect",
      GOOGLE_CLIENT_ID:
        "151524689994-5879o8394mmdqsl1pfkbeg1ieggajn0j.apps.googleusercontent.com",
      LOGOUT_URL: "https://www-uat26.gotosea.com/emailprefs/logout-redirect",
      COOKIE_SECURE: false,
    });
  } else if (window.location?.hostname.includes("-uat")) {
    const uatNumber = window.location.hostname.match(/-uat(\d*)/)[1];
    setAuthConfigValues({
      AWS_CLIENT_ID: "26begqed5jfgd8l37g6j0f85rd",
      USER_POOL_ID: "us-east-1_uhvOON0Lg",
      OAUTH_DOMAIN: "auth-uat.gotosea.com",
      AWS_REDIRECT_URI: `https://www-uat${uatNumber}.gotosea.com/login-redirect`,
      GOOGLE_CLIENT_ID:
        "151524689994-5879o8394mmdqsl1pfkbeg1ieggajn0j.apps.googleusercontent.com",
      LOGOUT_URL: `https://www-uat${uatNumber}.gotosea.com/emailprefs/logout-redirect`,
      COOKIE_SECURE: false,
    });
  } else if (window.location?.hostname.endsWith("-stag.gotosea.com")) {
    setAuthConfigValues({
      AWS_CLIENT_ID: "qd2hqfd09jrbhl0fmqjprm5u3",
      USER_POOL_ID: "us-east-1_lQjbOJzZ3",
      OAUTH_DOMAIN: "auth-stag.gotosea.com",
      AWS_REDIRECT_URI: "https://www-stag.gotosea.com/login-redirect",
      GOOGLE_CLIENT_ID:
        "315957829157-f2tanjs02aa8h2bsr2q39a7qbnnb833d.apps.googleusercontent.com",
      LOGOUT_URL: "https://www-stag.gotosea.com/emailprefs/logout-redirect",
    });
  }

  // Cookie domain for local development.
  if (window.location?.hostname === "localhost") {
    setAuthConfigValues({
      COOKIE_DOMAIN: "localhost",
    });
  }
}

// eslint-disable-next-line react/prop-types
const PoseidonApp = ({ Component, pageProps }) => {
  const [loadTealium, setLoadTealium] = useState(false);

  useEffect(() => {
    setCsrfToken(publicRuntimeConfig.POSEIDON_NEXT_API);

    const handleUserInteraction = () => {
      setLoadTealium(true);
      // Remove event listeners to ensure the script is only added once
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("scroll", handleUserInteraction);
    };

    // Listen to multiple events to cover different types of interactions
    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("scroll", handleUserInteraction);

    Router.events.on("routeChangeComplete", firePageView);

    return () => {
      Router.events.off("routeChangeComplete", firePageView);
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("scroll", handleUserInteraction);
    };
  }, []);

  let oneTrustScriptId = "fc38267f-6222-45a6-b50d-68b6ef25d878";
  if (publicRuntimeConfig.TEALIUM_ENV !== "prod") {
    // The test script matches the production script functionality exactly except:
    // There is no cache, meaning changes can be viewed immediately.
    oneTrustScriptId += "-test";
  }
  const oneTrustOptanonWrapper = `
      function OptanonWrapper() {
          const event = new Event('onetrust-categories-updated');
          window.dispatchEvent(event);
      }
  `;

  // eslint-disable-next-line react/prop-types
  const { key, ...props } = pageProps;

  return (
    <>
      <Head>
        <meta
          data-rh="true"
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />

        {/* OneTrust Cookies Consent Notice start for gotosea.com */}
        {/* eslint-disable-next-line @next/next/no-sync-scripts */}
        <script
          src={`https://cdn.cookielaw.org/consent/${oneTrustScriptId}/otSDKStub.js`}
          type="text/javascript"
          data-domain-script={oneTrustScriptId}
        />

        {/* OptanonWrapper is executed on each page load, or whenever the user saves changes to the privacy settings in the Preference Center. */}
        {/* eslint-disable react/no-danger */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{ __html: oneTrustOptanonWrapper }}
        />
        {/* eslint-enable react/no-danger */}
        {/* End OneTrust Cookie Consent Notice */}
      </Head>
      <PagesTopLoader color="#1D616A" showSpinner={false} />
      {loadTealium && (
        <Script
          src={`https://tags.tiqcdn.com/utag/usnews/main/${publicRuntimeConfig.TEALIUM_ENV}/utag.js`}
          strategy="afterInteractive"
          key="utag"
        />
      )}
      <ErrorBoundary>
        <FavoritingProvider>
          <Component key={key} {...props} />
        </FavoritingProvider>
      </ErrorBoundary>
    </>
  );
};

export default PoseidonApp;
