import getAPIQuery from "utils/getAPIQuery";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const baseUrl =
  publicRuntimeConfig.POSEIDON_NEXT_BASE || "https://www.gotosea.com";

// Make sure the baseUrl has a trailing slash as normalized by Next.js
// for the homepage canonical url or we'll get hydration errors.
const normalizedBaseUrl = baseUrl.endsWith("/") ? baseUrl : `${baseUrl}/`;

export default {
  // `label` for breadcrumb name, `file` for Link href,
  // `canonicalUrl` (or `path`) for "Link as" or anchor href.

  odysseusBooking: () => {
    return {
      path: "https://book.gotosea.com/web/cruises/details.aspx",
      canonicalUrl: "https://book.gotosea.com/web/cruises/details.aspx",
    };
  },

  myCruiseBookings: () => {
    return {
      path: "https://book.gotosea.com/web/customer/bookings.aspx?siid=330075",
      canonicalUrl:
        "https://book.gotosea.com/web/customer/bookings.aspx?siid=330075",
    };
  },

  userAgreement: () => {
    return {
      file: "/info/user-agreement.js",
      path: "/user-agreement",
      canonicalUrl: `${baseUrl}/user-agreement`,
    };
  },

  privacyPolicy: () => {
    return {
      path: "https://www.usnews.com/features/info/privacy",
      canonicalUrl: "https://www.usnews.com/features/info/privacy",
    };
  },

  search: (options = {}) => {
    // Destructure filters and sort; provide default empty objects so that
    // calling search() without parameters also works.
    const { filters = {}, sort = {} } = options;
    const queryString = getAPIQuery({ filters, sort });

    return {
      file: "/search/index.js",
      path: `/search${queryString}`,
      canonicalUrl: `${baseUrl}/search${queryString}`,
    };
  },

  cannedSearch: (urlname) => {
    return {
      file: "/[canned_search_urlname].js",
      path: `/${urlname}`,
      canonicalUrl: `${baseUrl}/${urlname}`,
    };
  },

  profile: (urlname, id) => {
    return {
      path: `/cruises/${urlname}-${id}`,
      canonicalUrl: `${baseUrl}/cruises/${urlname}-${id}`,
    };
  },

  favorites: () => {
    return {
      label: "Saved Sailings",
      file: "/my-cruises/index.js",
      path: `/my-cruises`,
      canonicalUrl: `${baseUrl}/my-cruises`,
    };
  },

  communicationPreferences: () => {
    return {
      label: "Communication Preferences",
      file: "/communication-preferences/index.js",
      path: "/communication-preferences",
      canonicalUrl: `${baseUrl}/communication-preferences`,
    };
  },

  reviews: () => {
    return {
      label: "Reviews and Testimonials",
      file: "/customer-reviews/index.js",
      path: "/customer-reviews",
      canonicalUrl: `${baseUrl}/customer-reviews`,
    };
  },

  affiliate: () => {
    return {
      label: "Affiliate",
      file: "/affiliate/index.js",
      path: "/affiliate",
      canonicalUrl: `${baseUrl}/affiliate`,
    };
  },

  about: () => {
    return {
      label: "About Us",
      file: "/about-us/index.js",
      path: "/about-us",
      canonicalUrl: `${baseUrl}/about-us`,
    };
  },

  home: () => {
    return {
      label: "Home",
      file: "/index.js",
      path: "/",
      canonicalUrl: normalizedBaseUrl,
    };
  },
};
