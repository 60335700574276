import Cookies from "universal-cookie";

const COOKIE_PREFIX = "gts";
const cookies = new Cookies();

// Function to make sure CSRF token is set
export const setCsrfToken = async (apiBaseUrl) => {
  const response = await fetch(`${apiBaseUrl}/set_csrf/`, {
    method: "GET",
    credentials: "include",
  });
  if (!response.ok) {
    // eslint-disable-next-line no-console
    console.error("Failed to set CSRF token");
  }
};

// CSRF token management
export const getCsrfToken = () => {
  return cookies.get("csrftoken");
};

// Session cookie, required by atlas-auth to track log-in vs. sign-up
// to distinguish front-ends across U.S. News apps
export const setCognitoVertical = () => {
  cookies.set("cogv", "gotosea");
};

// For search results card view vs table view
export const setViewPreference = (view) => {
  // `view` should be "0" or "1"
  const expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 8); // Add 8 hours to current time
  cookies.set(`${COOKIE_PREFIX}_selected_view`, view.toString(), {
    path: "/",
    expires: expirationDate,
  });
};

export const getViewPreference = () => {
  const val = cookies.get(`${COOKIE_PREFIX}_selected_view`);
  return val !== undefined ? parseInt(val, 10) : undefined;
};

export const removeViewPreference = () => {
  cookies.remove(`${COOKIE_PREFIX}_selected_view`, { path: "/" });
};

// For including taxes and fees in prices
export const setTaxFeeDisplayCookie = () => {
  const expirationDate = new Date(2147483647 * 1000);
  cookies.set(`${COOKIE_PREFIX}_display_tax_fee`, "true", {
    path: "/",
    expires: expirationDate,
  });
};

export const removeTaxFeeDisplayCookie = () => {
  cookies.remove(`${COOKIE_PREFIX}_display_tax_fee`, { path: "/" });
};

export const isTaxFeeDisplaySet = () =>
  cookies.get(`${COOKIE_PREFIX}_display_tax_fee`) === "true";

// For membership program (currently defunct)
export const setMembershipUnlockCookie = () => {
  const expirationDate = new Date(2147483647 * 1000);
  cookies.set(`${COOKIE_PREFIX}_membership_unlock`, "true", {
    path: "/",
    expires: expirationDate,
  });
};

export const isMembershipUnlocked = () =>
  cookies.get(`${COOKIE_PREFIX}_membership_unlock`) === "true";

export const setMembershipPromotionCookie = () => {
  cookies.set(`${COOKIE_PREFIX}_membership_promotion`, "off", { path: "/" });
};

// adhering to affirmative names; value is truthy if cookie doesn't exist
export const isMembershipPromotionActive = () =>
  cookies.get(`${COOKIE_PREFIX}_membership_promotion`) == null;
